// Macedonian
export default{
    form: {
        startSurveyButton: "Да почнеме",
        nextButton: "Следно",
        submitAnswerButton: "Поднеси одговор",
        continueButton: "Продолжи",
        finishButton: "Заврши",
        headerPercentageCompletion: 'завршено',
        headerQuestionsCompleted: 'прашања',
        headerDefaultErrorMessage: 'Упс! Нешто тргна наопаку.',
        brandingPoweredBy: 'Напојувано од',
        brandingPromoWhySurvey: 'Зошто анкета кога можете да користите <b>{product}</b>?',
        brandingPromoGetStarted: 'Започнете денес!',
        submissionPending: "Вашата voiceform се поднесува. Ве молиме, не го затворајте прозорецот на прелистувачот.",
        submissionCanCloseWindow: "Вашата voiceform е успешно поднесена. Сега можете да го затворите прозорецот 👍",
        mediaAttachmentOverlayCloseButton: "Затвори",
        expiredFormHeader: "Ви благодариме што не посетивте!",
        expiredFormMessage: "Изгледа дека оваа анкета е истечена. Контактирајте го креаторот на voiceform за да го известите.",
        notPublishedFormHeader: "Voiceform не е објавен!",
        notPublishedFormMessage: "Изгледа дека оваа анкета не е објавена. Контактирајте го креаторот на voiceform за да го известите.",
        notPublishedFormRetryButton: "Обиди се повторно",
        errorFormHeader: "Упс!",
        errorFormMessage: "Можеби нешто тргна наопаку. Ве молиме, обидете се подоцна.",
        errorFormRetryButton: "Обиди се повторно",
        emptyFormHeader: "Ви благодариме што не посетивте!",
        emptyFormMessage: "Упс! Изгледа дека овој образец е празен, без прашања или полиња за пополнување. Се извинуваме за непријатностите. Контактирајте го креаторот на овој образец за да го известите за проблемот. Ви благодариме за разбирањето и соработката!",

        restoreFormHeader: "Изгледа дека имате незавршен образец!",
        restoreFormMessage: "Дали сакате да продолжите од каде што застанавте?",
        restoreFormButton: "Продолжи со образецот",
        restoreFormButtonNew: "Почни одново",

        audioPermissionHeader: "Дали сакате да одговорите со глас?",
        audioPermissionMessage: "Говорењето ќе го направи одговарањето брзо и лесно.",
        audioPermissionAcceptButton: "Да, сакам",
        audioPermissionDenyButton: "Не, благодарам",
        audioPermissionErrorHeader: "Микрофонот не е достапен",
        audioPermissionErrorMessage: "Проверете ги дозволите на прелистувачот за да овозможите пристап до микрофонот.",
        audioPermissionErrorButton: "Во ред",
        audioRecorderFailedUpload: "Не успеа да се подигне снимката",
        silenceDetectorError: "Не ве слушаме. Проверете ги поставките на микрофонот. Совет: Можеби користите надворешен микрофон.",
        audioRecorderButtonRecord: "Сними",
        audioRecorderButtonRecordMore: "Сними повеќе",
        voiceResponseSelectionMessage: "Изберете како сакате да одговорите...",
        voiceResponseSelectionOrDivider: "или",
        voiceResponseTextInputPlaceholder: "Внесете одговор",
        voiceResponseTextInputCharacterCounterMinMessage: "Внесете најмалку {min} знаци",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} знаци (минимум {min} знаци)",
        voiceResponseTextInputCharacterValidationMessage: "{count} знаци (минимум {min} знаци)",

        // checkbox
        checkboxValidationTooFew: "Мора да изберете најмалку {min} опција(и)",
        checkboxValidationTooMany: "Мора да изберете најмногу {max} опција(и)",
        checkboxNoneOfTheAboveOption: "Ниту едно од горенаведените",
        checkboxInvalidAnswer: "Невалиден одговор.",

        // datepicker
        datePickerPlaceholder: "Изберете датум",

        // dropdown
        // email
        emailLabel: "Е-пошта",
        emailInvalid: "Невалидна е-пошта.",
        // file-upload
        fileUploadPluginNameCamera: "Камера",
        fileUploadPluginNameCameraVideo: "Сними видео",
        fileUploadPluginScreenCast: "Снимање на екран",
        fileUploadVideoImportFilesDefault: 'Сними или подигни видео преку:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Изберете опција за снимање видео:',
        fileUploadVideoImportFilesNoCamera: 'Притиснете копче за снимање на екранот',
        fileUploadVideoImportFilesNoScreenCast: 'Притиснете копче за снимање видео',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Нема достапни опции за снимање видео',

        // matrix
        matrixValidationMessage: "Одговорот е валиден.",
        matrixRow: "Ред",
        matrixColumn: "Колона",
        matrixRowRequired: "Ред {rowTitle} е задолжителен.",
        matrixRadioGroup: "Радио група",
        matrixCheckboxGroup: "Група за потврдување",
        matrixGroupRequired: "За ред {rowTitle}. {type} {groupTitle} е задолжително.",
        matrixColumnRequired: "За ред {rowTitle}. Колона {columnTitle} е задолжителна.",
        matrixColumnInvalid: "За ред {rowTitle}. Колона {columnTitle} не е валидна.",
        matrixRequired: "Задолжително.",
        matrixNumericMustBeNumber: "Мора да биде број.",
        matrixNumericMustBeGreaterThenMin: "Мора да биде поголемо од {min}.",
        matrixNumericMustBeLessThenMax: "Мора да биде помало од {max}.",
        matrixNumericMustBeInteger: "Мора да биде цел број.",
        matrixNumericInvalidNumber: "Невалиден број.",

        // name
        nameLabel: "Име",
        nameInvalid: "Наведи име и презиме.",

        // nps
        npsNotLikely: "Воопшто не е веројатно",
        npsExtremelyLikely: "Многу веројатно",

        // numeric input
        numericInputRequired: "Задолжително.",
        numericInputMustBeNumber: "Мора да биде број.",
        numericInputMustBeGreaterThenMin: "Мора да биде поголемо од {min}.",
        numericInputMustBeLessThenMax: "Мора да биде помало од {max}.",
        numericInputMustBeInteger: "Мора да биде цел број.",
        numericInputInvalidNumber: "Невалиден број.",
        numericInputPlaceholder: "Внеси број",

        // phone
        phoneInvalid: "Телефонскиот број е невалиден.",
        phoneCountrySelectorLabel: 'Код на држава',
        phoneCountrySelectorError: 'Изберете држава',
        phoneNumberLabel: 'Телефонски број',
        phoneExample: 'Пример:',

        // boolean
        booleanYesLabel: "Да",
        booleanNoLabel: "Не",

        //questionStep
        questionStepAudioQuestionLabel: "Аудио прашање",

        // errors
        invalidPhoneNumber: "{phone} е невалиден телефонски број.",
        invalidEmail: "{email} е невалидна е-пошта.",
        questionIsRequired: "Прашањето е задолжително.",
        answerIsNotValid: "Одговорот не е валиден.",
        unfinishedProbingDialogError: "Ве молиме, завршете го дијалогот.",
        cannotResumePartialResponse: "Не може да се продолжи со делумниот одговор.",
        failedToSubmitForm: "Неуспешно испраќање на образецот. Проверете ја интернет врската и обидете се повторно.",

        //language picker
        searchLanguage: "Пребарај јазик",
    }
}
